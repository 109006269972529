import React from 'react'
import { graphql } from 'gatsby'
import ScrollyTelling from '../components/modules/ScrollyTelling/ScrollyTelling'
import { useGTM } from '@hooks/useGTM'
import NavBar from '@root/NavBar'

export default function StoriesTemplate({ data, path }) {
	const page = data.main
	const locale = page.locale?.locale ? page.locale?.locale : ''
	useGTM({ locale, path, pageType: page?.pageOptionsPageType?.pageType })

	return (
		<>
			<NavBar />
			<ScrollyTelling data={page.postTypeStories} />
		</>
	)
}

export const query = graphql`
	query ($post: String) {
		main: wpStories(id: { eq: $post }) {
			...PostStoriesFragment
			...StorySlidesFragment
			pageOptionsPageType {
				pageType
			}
		}
	}
`
